/* You can add global styles to this file, and also import other style files */
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import "~@ng-select/ng-select/themes/material.theme.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

@import '~@angular/material/theming';
@include mat-core();

// custom font
$custom-typography: mat-typography-config($font-family: 'Source Sans Pro, sans-serif',
    );
@include mat-core($custom-typography);

$my-app-primary: mat-palette($mat-indigo);
$my-app-accent: mat-palette($mat-pink, A200, A100, A400);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);

@include angular-material-theme($my-app-theme);

// color variable

$theme-color-blue: #009ADE !default;
$theme-gradient: linear-gradient(149deg, $theme-color-blue 19%, $theme-color-blue 51%, rgba(0, 201, 141, 1) 100%) !default;
$btn-gradient: linear-gradient(180deg, $theme-color-blue 0%, #0E81B5 100%) !default;
$error-color: #EF3842 !default;
$question-grey: #334252;
$page-bg-color: #F3F9FD;
$white-color: #FFFFFF;
$pass-green: #10a52a;
$fail-red: #EF3842;

.form-control {
    &:focus {
        box-shadow: none;
    }
}

.alert-box {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 11;
    bottom: 2rem;

    .alert {
        padding: 10px 50px;
        width: 50%;
        text-align: center;

        @media (max-width: 575.98px) {
            width: auto;
        }
    }
}

// Pre Login Leftside

.prelogin_leftside {
    text-align: center;
    min-height: 100vh;
    height: 100%;
    background: $theme-color-blue;
    background: $theme-gradient;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 33%;

}

.prelogin_img {
    width: 70%;
    margin-top: 30px;
}

.prelogin_text {
    text-align: center;
    color: $white-color;
    padding: 0 40px;

    h1 {
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
    }

    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #EAEAEA;
    }
}

// Pre Login Rightside


.prelogin_rightside {
    text-align: center;
    min-height: 100vh;
    padding: 40px 0;
}

.prelogin_form_container {
    width: 450px;
    margin: 50px auto 0;
    text-align: left;
}

@media (max-width: 575.98px) {
    .prelogin_form_container {
        width: 90%;
    }
}

.prelogin_form_container h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #101727;
    margin-bottom: 5px;
}

.prelogin_form_container p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #596277;
}

// custom form

.cst_form {
    .form-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #3D4B5A;

        span {
            color: $error-color;
            margin-left: 5px;
        }
    }

    .form-control {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #242F48;

        &:focus {
            box-shadow: none;
        }
    }

    .form-control.invalid {
        border-color: $error-color;
    }
}


.invalid_feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: $error-color;
}


input {
    line-height: 1.7;
}


.prelogin_btn {
    background: $btn-gradient;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: $white-color;
    border: none;
    width: 200px;
    padding: 15px;
    border-radius: 6px;

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 12px;

    }

    &.disabled {
        color: $white-color;
    }

    &:active {
        color: $white-color !important;
    }

    &:hover {
        background: linear-gradient(180deg, $theme-color-blue 0%, $theme-color-blue 100%);
        color: $white-color !important;
    }
}

.btn-check:checked+.prelogin_btn,
.prelogin_btn.active,
.prelogin_btn.show,
.prelogin_btn:first-child:active,
:not(.btn-check)+.prelogin_btn:active {
    background-color: $theme-color-blue;
}

.cst_btn {
    background: $btn-gradient;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $white-color;
    border: none;
    width: 130px;
    padding: 10px;
    border-radius: 6px;
    height: 40px;

    &.disabled {
        color: $white-color !important;
    }

    &:active {
        color: $white-color !important;
    }

    &:hover {
        background: linear-gradient(180deg, $theme-color-blue 0%, $theme-color-blue 100%);
        color: $white-color !important;
    }
}

.register_btn{
    border-radius: 4px;
    background: linear-gradient(180deg, #10A52A 0%, #107E24 100%);
    color: #FFF;
    font-size: 14px;
    margin: 14px auto 0 ;
    font-weight: 400;
    line-height: 20px;
    min-width: 136px;
    &.disabled {
        color: $white-color !important;
    }

    &:active {
        color: $white-color !important;
        background: linear-gradient(180deg, #10A52A 0%, #107E24 100%);

    }

    &:hover {
        background: linear-gradient(180deg, #10A52A 0%, #107E24 100%);
        color: $white-color !important;
    }
    }

.prev_btn {
    text-decoration: underline;
    color: rgb(45, 179, 237);
    border: none;
}

.btn-check:checked+.cst_btn,
.cst_btn.active,
.cst_btn.show,
.cst_btn:first-child:active,
:not(.btn-check)+.cst_btn:active {
    background-color: $theme-color-blue;
}

.outline_btn {
    background: $white-color;
    border: 1px solid $theme-color-blue;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: $theme-color-blue;
    padding: 10px;
    width: 130px;

    &:active {
        color: $theme-color-blue !important;
        border: 1px solid $theme-color-blue;
        background: $white-color;
    }

    &:hover {
        background-color: $white-color;
        color: $theme-color-blue;
        border: 1px solid $theme-color-blue;
    }
}

.presubmission_btn {
    background: $btn-gradient;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $white-color;
    border: none;
    width: 240px;
    padding: 10px;
    border-radius: 6px;
    height: 40px;

    &.disabled {
        color: $white-color;
    }

    &:active {
        color: $white-color !important;
    }

    &:hover {
        background: linear-gradient(180deg, $theme-color-blue 0%, $theme-color-blue 100%);
        color: $white-color !important;
    }
}

.btn-check:checked+.presubmission_btn,
.presubmission_btn.active,
.presubmission_btn.show,
.presubmission_btn:first-child:active,
:not(.btn-check)+.presubmission_btn:active {
    background-color: $theme-color-blue;
}

.grey_btn {
    background: #A1B3B7;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: $white-color;
    border: none;
    width: 130px;
    padding: 10px;
    border-radius: 6px;
    height: 40px;

    &.disabled {
        color: $white-color;
    }

    &:active {
        color: $white-color !important;
    }

    &:hover {
        background-color: #819397;
        color: $white-color !important;
    }
}

.btn-check:checked+.grey_btn,
.grey_btn.active,
.grey_btn.show,
.grey_btn:first-child:active,
:not(.btn-check)+.grey_btn:active {
    background-color: #819397;
}

.collapsing {
    -webkit-transition: none !important;
    transition: none !important;
    display: none !important;
}

.add_newbtn {
    width: 200px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
        color: $white-color;
    }

    img {
        height: 14px;
        padding-right: 10px;
    }
}

.link_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #7f8595;
    border: none;

    @media (max-width: 575.98px) {
        margin-bottom: 10px;
    }
}

.signup_link {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #596277;
    text-decoration: none;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    span {
        cursor: pointer;

        color: $theme-color-blue;
    }
}

.back_link {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: $theme-color-blue;
}

.forgot_password {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $theme-color-blue;
    text-decoration: none;

    @media (max-width:767.98px) {
        font-size: 12px;
    }
}

// custom checkbox

.cst_form_group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.cst_form_group label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #101727;

    @media (max-width: 768px) {
        font-size: 12px;
    }

}

.cst_form_group label:before {
    content: '';
    background-color: transparent;
    border: 1px solid #A8B5BC;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 2px;
}

.cst_form_group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 8px;
    width: 5px;
    height: 11px;
    border: solid $white-color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.cst_form_group input:checked+label::before {
    content: '';
    background: $theme-color-blue;
    border: 1px solid $theme-color-blue;

}


// sidebar

.cst_sidebar {
    position: fixed;
    height: 100%;
    width: 280px;
    min-height: 100vh;
    left: 0px;
    top: 0px;
    background: $theme-gradient;
    padding: 30px 0;
    transition: all 0.3s;
    z-index: 999;

    @media (max-width: 768px) {
        left: 0px;
    }

    .nav-item.active {
        background: #1396CE;
    }

    .nav-link {
        padding: 15px 35px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: $white-color;



    }
}

.hr_border1 {
    border: 1px solid #2CA4D8;
    margin: 0 15px;
}

.hr_border2 {
    border: 1px solid #0d8ec7;
    margin: 0 15px;
}

.submenu_link {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: $white-color;
    padding: 12px 12px 12px 70px;
    display: flex;
    position: relative;

    &:hover {
        color: $white-color;
    }

    .dash {
        height: 1px;
        width: 12px;
        background-color: $white-color;
        position: absolute;
        left: 39px;
        top: 19px;
    }
}

.main-content-container {
    background: $page-bg-color;
    min-height: 100vh;
    padding-left: 280px;
    transition: all 0.3s;

    @media (max-width: 768px) {
        padding-left: 0;
    }
}

.toogle-sidebar {
    left: -280px;
}

.toogle-content {
    padding-left: 0;
}

.toogle-header {
    padding-left: 60px;
}

.cst_header {
    background: $white-color;
    box-shadow: 0px 4px 15px rgba(211, 206, 206, 0.36);
    padding: 12px 30px 12px 300px;
    position: fixed;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;
    z-index: 999;

    @media (max-width: 767.98px) {
        padding: 12px 10px 12px 10px;

    }

    .logo {
        @media (max-width: 767.98px) {
            width: 120px;
        }
    }

    &.form_header {
        // padding-left: 30px;

        h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: #101727;
            margin-bottom: 0;
            width: 400px;
            @media (max-width: 991.98px) {
                font-size: 16px;
                width: 300px;
            }
            @media (max-width: 767.98px) {
                display: none;
            }
        }
    }

    @media (max-width: 768px) {
        // padding-left: 30px;
    }

    .nav-item {
        display: flex;
        align-items: center;

        &.user_detail {
            margin-left: 10px;

            .dropdown {
                .dropdown-toggle{
                    &::after{
                        display: none;
                    }
                }
                .dropdown-menu {
                    background: $white-color;
                    border-radius: 6px;
                    transform: translate(0px, 52px) !important;

                    li {
                        border-bottom: 1px solid #ECEEEE;

                        .dropdown-item {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 12px;
                            color: #242F48;
                            padding: 10px 20px;
                            display: flex;
                            align-items: center;

                            &:focus {
                                background-color: #e9ecef;
                            }

                            &:active {
                                background-color: $theme-color-blue;
                                color: #fff;
                            }

                            em {
                                padding-right: 6px;
                            }
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }

            }

            .shortname {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background: $theme-color-blue;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white-color;
                margin-right: 10px;
            }

            .name {
                color: #101727;
                font-weight: 500;
                margin-right: 10px;

                @media (max-width: 768px) {
                    display: none;
                }
            }

            span {
                display: block;
                color: #4D5875;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
}

.cst_header-toogle {
    padding-left: 40px;

    @media (max-width: 767.98px) {
        padding-left: 10px;

    }
}


.main-content {
    padding: 90px 40px 30px 40px;

    @media (max-width: 768px) {
        padding: 90px 10px;
    }

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #101727;
        margin-bottom: 5px;
    }
}


.breadcrumb .breadcrumb-item a {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #899BBD;
    text-decoration: none;
}

.breadcrumb .breadcrumb-item.active {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #101727;
}

.whitebox_container {
    background: $white-color;
    box-shadow: 0px 0px 2px 0px #97C1DD;
    border-radius: 6px;
    padding: 30px;

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #242F48;
        margin-bottom: 30px;
    }
}


.table_container {
    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #242F48;
        margin-bottom: 0;

        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
}

.cst_table {
    // background: #DEEAF2;
    --bs-table-bg: none;

    thead {
        background: #DEEAF2;

        th {
            font-weight: 600;
            font-size: 13px;
            line-height: 14px;
            color: #3A4A55;
            // padding: 12px;
            white-space: nowrap;

            p{
                display: flex;
                align-items: center;
                margin-bottom: 0;
                span{
                    padding-left: 3px;
                }
            }
        }
    }

    tbody {
        tr {
            // background: #F4F8FA;
            vertical-align: middle;

            th,
            td {
                // padding: 12px;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                padding: 16px 8px;
                img {
                    cursor: pointer;
                }
            }
        }

    }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #fff;
    --bs-table-accent-bg: #fff;
}

.table_search {
    width: 210px;
    margin-bottom: 15px;
}

.search_input {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    width: 250px;
    color: rgba(89, 98, 119, 0.56);
}

// pagination

.cst_pagination .ngx-pagination {
    border-radius: 4px;
    color: $white-color;
    margin-bottom: 0;
    padding-left: 0;

    li {
        background-color: $white-color;
        padding: 8px 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #22252F;
        border: 1px solid #EBE9F1;
        margin: 0;

        &.current {
            background: $theme-color-blue;
            color: $white-color;
            border: 1px solid $theme-color-blue;
            padding: 8px 17px;
        }

        a {
            padding: 0 5px;

            &:hover {
                background-color: $white-color;
            }
        }
    }
}

.cst_pagination .ngx-pagination .disabled:hover {
    background-color: $white-color;
}

// custom tabs
.form_wrapper {
    background: $page-bg-color;
}

.cst-tabs {
    .nav-tabs {
        border-bottom: 1px solid #DDE7FF;
    }

    .nav-tabs .nav-link {
        background: $white-color;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: $white-color;
        border-radius: 5px 5px 0px 0px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #232F33;
        margin-bottom: 1px;
        padding: 10px 35px;
        min-width: 150px;

        @media (max-width: 768px) {
            min-width: auto;
            padding: 10px 20px;
        }

    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        background: $theme-color-blue;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: $white-color;
        border-radius: 5px 5px 0px 0px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: $white-color;
        border: none;
    }
}

.cst-tabs .tab-content {
    .card {
        border: none;
    }

    .card-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        height: 48px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-body {
        background: $white-color;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.11);
        border-radius: 0px 0px 6px 6px;

        .start_date,
        .end_date {
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: #7992A4;
            margin-right: 15px;

            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: #232F33;
                display: block;
            }
        }
    }
}

.pending_status {
    background: $fail-red;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: $white-color;
    padding: 3px 10px;
}

.complete_status {
    background: $pass-green;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: $white-color;
    padding: 3px 10px;
}

.edit_action {
    text-align: center;

    span {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        text-transform: uppercase;
        color: #0091B1;
        display: block;
        cursor: pointer;
    }
}



// intake form

.form_main_content {
    padding: 110px 80px;
    min-height: 100vh;

    @media (max-width: 767.98px) {
        padding: 110px 0px;
    }
}

.cst_accordian {
    --bs-accordion-border-color: transparent;
    --bs-accordion-bg: none;
    margin-bottom: 56px;

    @media (max-width: 767.98px) {
        margin-bottom: 0px;
    }

    h2 {
        margin-bottom: 0;
    }

    .accordion-item {
        margin-bottom: 10px;
    }

    .accordion-button.collapsed {
        border-radius: 5px;

    }

    .accordion-button {
        background: #EBF3F8;
        border: 1px solid #C0D9EA;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #455763;
        border-radius: 5px 5px 0px 0px;

        @media (max-width: 767.98px) {
            padding: 12px 10px;
            font-size: 14px;
            line-height: 20px;
        }

        &:focus {
            box-shadow: none;
        }

        span {
            font-weight: 700;
            padding-right: 5px;
        }

        &.fixed-tab {
            position: fixed;
            right: 0;
            width: calc(100% - 360px);
            left: 330px;
            z-index: 999;

            @media (max-width: 991.98px) {
                left: 30px;
                width: calc(100% - 60px);

            }

            @media (max-width: 767.98px) {
                left: 15px;
                width: calc(100% - 30px);
                top: 195px;
            }

            @media (max-width: 575.98px) {
                top: 245px;
            }
        }

        &.error {
            border-color: $error-color;
        }
    }

    .accordion-button:not(.collapsed)::after {
        background-image: var(--bs-accordion-btn-icon);
    }

    .accordion-body {
        padding: 30px;
        background: $white-color;
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 4px 4px;

        @media (max-width: 991.98px) {
            padding: 20px;
        }


        h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
            color: #01A0C3;
            margin-bottom: 24px;
        }

        p.question {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $question-grey;
            margin-bottom: 7px;
            margin-right: 30px;

            em {
                padding-left: 5px;
                color: #40454A;
            }

            span.bold {
                font-weight: 700;
            }

            span.mandatory {
                color: $error-color;
            }
        }

        .question-box {
            position: relative;

            .add-note-box {
                border: 1px solid #B2CAD7;
                background: #EDF6FB;
                position: absolute;
                right: 0px;
                top: 0;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .add-note {
                    color: #799FB4;
                    padding: 5px;
                }
            }
        }


        .form-control {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #242F48;
            width: 600px;

            &:focus {
                box-shadow: none;
            }

            @media (max-width: 991.98px) {
                width: 100%;
            }
        }

        .form-control.invalid {
            border-color: $error-color;
        }
    }
}

::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #C6C6C6;
    padding: 6px 0px;
}



.mb-36 {
    margin-bottom: 36px;
}

// custom radio button

.cst-radio {
    padding-left: 0;
    margin-right: 3rem;

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #8CAAC0;
        border-radius: 100%;
        background: $white-color;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: $theme-color-blue;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


// Pre-submission

.pre_submission_main {
    width: 80%;
    margin: 0 auto;

    @media (max-width: 767.98px) {
        width: 100%;
    }

    .pre_submission_box {
        background-color: $white-color;
        min-height: 70vh;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 40px 50px;

        p.question {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #334252;
            margin-bottom: 7px;

            span.bold {
                font-weight: 700;
            }

            span.mandatory {
                color: $error-color;
            }
        }

        .form-control,
        .form-select {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #242F48;
            width: 400px;

            &:focus {
                box-shadow: none;
            }

            @media (max-width: 991.98px) {
                width: 100%;
            }
        }
    }
}


// custome stepper

.cst-stepper {
    //TODO- change stepper css and width
    background-color: transparent;
    // width: 85%;
    // margin: 0 auto;

    @media (max-width: 991.98px) {
        width: 100%;
    }
    .mat-horizontal-stepper-wrapper{
       align-items: center;
    }
    .mat-stepper-horizontal-line {
        border-top-color: #D8E7EF;
        margin: 23px -16px;

        @media (max-width: 767.98px) {
            margin: 15px -16px;

        }
    }

    .mat-step-header .mat-step-icon {
        background-color: #FFFFFF;
        width: 46px;
        height: 46px;
        color: #B7CFDD;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        border: 2px solid #B7CFDD;
        margin-right: 0;

        @media (max-width: 767.98px) {
            width: 30px;
            height: 30px;
            font-size: 16px;
            line-height: 18px;
        }
    }

    .mat-step-header .mat-step-label.mat-step-label-active {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #B7CFDD;
        margin-top: 5px;
        white-space: normal;

        @media (max-width: 767.98px) {
            font-size: 14px;
            line-height: 16px;
            margin-left: 10px;
            text-align: left;
        }

    }


    .mat-horizontal-stepper-header {
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 100px;
        padding: 0;
    }

    .mat-step-header .mat-step-icon-selected {
        color: $theme-color-blue;
        border: 2px solid $theme-color-blue;
        font-weight: 600;
    }

    .mat-step-header .mat-step-label.mat-step-label-selected {
        color: #101727;
        font-weight: 600;
    }

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover:not([aria-disabled]),
    .mat-step-header:hover[aria-disabled=false] {
        background-color: transparent;
    }

    .mat-horizontal-stepper-header-container {
        align-items: self-start;
    }

    // step status done

    .mat-step-header .mat-step-icon-state-done {
        background-color: #FFFFFF;
        width: 46px;
        height: 46px;
        color: #B7CFDD;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        border: 2px solid #B7CFDD;
        margin-right: 0;

        @media (max-width: 767.98px) {
            width: 30px;
            height: 30px;
            font-size: 16px;
            line-height: 18px;
        }
    }

    .mat-step-header .mat-step-label.mat-step-icon-state-done {
        color: $theme-color-blue;
        font-weight: 500;
    }

    .mat-stepper-horizontal-line-done {
        border: 1px solid $theme-color-blue;
    }

    .mat-step-show-error {
        border: 2px solid red !important;
        color: red !important;
    }

    .mat-step-show-label-error {
        color: red !important;
    }

    .mat-step-show-done {
        border: 2px solid $theme-color-blue !important;
        background-color: $theme-color-blue !important;
        color: #FFFFFF !important;
        font-weight: 600 !important;
    }

    .mat-step-show-label-done {
        color: $theme-color-blue !important;
    }

    .mat-step-header .mat-step-icon.mat-step-icon-state-error {
        background-color: #FFFFFF;
        width: 46px;
        height: 46px;
        color: #B7CFDD;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        border: 2px solid #B7CFDD;
        margin-right: 0;

        @media (max-width: 767.98px) {
            width: 30px;
            height: 30px;
            font-size: 16px;
            line-height: 18px;
        }
    }

    .mat-step-header .mat-step-label.mat-step-label-error {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #B7CFDD;
        margin-top: 5px;
        white-space: normal;

        @media (max-width: 767.98px) {
            font-size: 12px;
            line-height: 16px;
        }

    }

    .mat-step-header .mat-step-icon-state-done~.mat-step-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #B7CFDD;
        margin-top: 5px;
        white-space: normal;

        @media (max-width: 767.98px) {
            font-size: 12px;
            line-height: 16px;
        }

    }
}

.fixed-stepper {
    .mat-horizontal-stepper-header-container {
        z-index: 899;
        position: fixed;
    }

    .mat-horizontal-content-container {
        margin-top: 130px;
        width: 100%;
    }
}

.new-solution {
    text-align: center;
    width: 65%;
    margin: 0 auto;
    padding: 30px 0;

    @media (max-width: 767.98px) {
        width: 100%;
        padding: 0;
    }

    img.add-solutionimg {
        margin-bottom: 30px;
    }

    p {
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        text-align: center;
        color: #577485;
        margin-bottom: 30px;

    }

    button {
        margin: 0 auto;
    }
}



.summary_main_content {
    padding: 190px 15px 50px;
    display: flex;

    @media (max-width: 767.98px) {
        padding: 220px 15px 50px;
    }

    @media (max-width: 577.98px) {
        padding: 275px 10px 50px;
    }

    h1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        text-transform: uppercase;
        color: #01A0C3;

        @media (max-width: 1199.98px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .list-group {
        width: 300px;
    }
}


.answer {
    padding-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    word-break: break-all;
    padding-right: 12px;

    @media (max-width: 991.98px) {
        height: auto;
    }

    &::before {
        content: "";
        display: block;
        height: 100%;
        width: 4px;
        background: #69CFAB;
        position: absolute;
        left: 0;

        @media (max-width: 991.98px) {
            height: 100%;
        }
    }
}

.hr-line {
    border: 1px solid #E8E8E8;
    margin: 15px 0;
}

.cst-btn-group {
    .btn {
        border: 1px solid #B2CAD7;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: #799FB4;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 5px 15px;

        em {
            font-size: 12px;
            margin-right: 3px;

        }
    }

    .pass.btn-check:checked+.btn,
    .pass.btn-check+.btn:hover {
        background-color: $pass-green;
        border-color: $pass-green;
        color: $white-color;
    }

    .fail.btn-check:checked+.btn,
    .fail.btn-check+.btn:hover {
        background-color: $fail-red;
        border-color: $fail-red;
        color: $white-color;
    }
}

.add-comment-btn {
    background: #EDF6FB;
    border: 1px solid #B2CAD7;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #799FB4;
    height: 24px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: 767.98px) {
    padding: 5px 10px;
        font-size: 10px;
    }
    &:hover,
    &:focus {
        background: #EDF6FB;
        border: 1px solid #B2CAD7;
        color: #799FB4;
    }

    img {
        width: 16px;
        margin-right: 5px;
    }

    span.comment-no {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: $error-color;
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        position: absolute;
        top: -5px;
        right: -5px;
        color: #FFFFFF;
    }
}

.comment-disabled {
    background: #EDF6FB;
    border: 1px solid #B2CAD7;
    color: #799FB4;
    pointer-events: none;

}

.btn-check:checked+.add-comment-btn,
.add-comment-btn.active,
.add-comment-btn.show,
.add-comment-btn:first-child:active,
:not(.btn-check)+.add-comment-btn:active {
    background: #EDF6FB;
    border: 1px solid #B2CAD7;
    color: #799FB4;
}

.comment-container {
    background: #F0F6F9;
    border: 1px solid #DEECF3;
    border-radius: 8px;
    // height: 110px;
    margin-top: 15px;
    // display: flex;
    align-items: end;
    padding: 10px;
    position: relative;

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #F0F6F9;
        position: absolute;
        right: 50px;
        top: -10px;
    }

    label {
        font-weight: 600;
        font-size: 11px;
        line-height: 8px;
        color: #457B96;
        // font-style: italic;
        margin-bottom: 10px;
    }

    .cmt-edit-btn {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .cmt-edit-delete-btn {
        position: absolute;
        right: 0px;
        bottom: 0;
    }

    .cmt-edit-delete-btn .delete-button {
        margin-right: 4px;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #415D6A;
    }

    .form-control {
        width: 100% !important;
    }
}

.assessment-response {
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #194C59;
        background: #DEECF3;
        border: 1px solid #A6D3EA;
        border-radius: 5px 5px 0px 0px;
        padding: 20px 30px;
        margin-bottom: 0;
        position: relative;

        @media (max-width: 575.98px) {
            font-size: 14px;
            line-height: 18px;
            padding: 20px 10px;

        }

        a {
            position: absolute;
            right: 30px;
            top: 22px;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            text-decoration-line: underline;
            color: $theme-color-blue;
            display: flex;
            cursor: pointer;
            align-items: center;

            @media (max-width: 575.98px) {
                font-size: 14px;
                line-height: 18px;
                right: 10px;

            }

            &:hover {
                text-decoration: underline;
                color: $theme-color-blue;
            }

            em {
                font-size: 20px;
                margin-right: 5px;
            }
        }
    }

    .response {
        padding: 20px 30px;
        background: $white-color;
        box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 4px 4px;
        @media (max-width: 767.98px) {
            padding: 10px;
        }
        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #4B5965;
            display: flex;
            justify-content: end;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }


    }
}

.submit-comment-btn {
    background: $theme-color-blue;
    border-radius: 4px;
    color: $white-color;
    height: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 10px 15px;

    span {
        font-weight: 700;
        font-size: 10px;
        line-height: 8px;
    }

    em {
        font-size: 18px;
    }
}

.submit-comment-btn.disabled {
    background: $theme-color-blue;
    border-radius: 4px;
    color: $white-color;
    opacity: 0.6;
}

.btn-check:checked+.submit-comment-btn,
.submit-comment-btn.active,
.submit-comment-btn.show,
.submit-comment-btn:first-child:active,
:not(.btn-check)+.submit-comment-btn:active,
.submit-comment-btn:hover {
    background: $theme-color-blue;
    color: $white-color;
    border-color: $theme-color-blue;
}

.cst-list-group {
    width: 230px;
    position: fixed;
    height: calc(100vh - 150px);
    overflow-y: auto;

    @media (max-width: 991.98px) {
        display: none;
    }

    ul {
        padding-left: 0;

        h3 {
            background: #01A0C3;
            border-radius: 4px;
            font-weight: 700;
            font-size: 13px;
            line-height: 22px;
            color: #FFFFFF;
            padding: 6px 15px;
            margin-bottom: 8PX;
        }

        li {
            list-style-type: none;
            padding-left: 25px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #4D6166;
            margin-bottom: 10px;
            position: relative;

            &::after {

                content: "";
                width: 3px;
                height: 3px;
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 15px;
                background: $theme-color-blue;
            }
        }
    }
}

.summary-rightside {
    width: 100%;
    margin-left: 250px;

    @media (max-width: 991.98px) {
        width: 100%;
        margin-left: 0;
    }
}

.e-signature {
    width: 350px;

    @media (max-width: 767.98px) {
        width: auto;
    }
}

.btn-close:focus{
    box-shadow: none;
}

.fix-note {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1C2328;
    position: absolute;
    right: 30px;
    top: 0;
    text-decoration: none;

    @media (max-width: 767.98px) {
        font-size: 12px;
        line-height: 18px;
        right: 15px;
    }

    &:hover {
        color: #1C2328;
    }
}

.fixed-summary-header {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0px;
    height: 121px;
    background: #f3f9fd;
    z-index: 898;
    padding-left: 280px;
    padding-right: 30px;

    @media (max-width: 991.98px) {
        padding-left: 30px;
    }

    @media (max-width: 767.98px) {
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
    }

    @media (max-width: 575.98px) {
        // height: 180px;

    }

    h1 {
        margin-bottom: 0;

        @media (max-width: 991.98px) {
            margin-bottom: 10px;

        }
    }
}

.heading {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 767.98px) {
        display: block;
        margin-top: 40px;

    }

    @media (max-width: 575.98px) {
        margin-top: 40px;

    }
}

.vendor-details {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4D6166;
    margin-right: 25px;

    @media (max-width: 1199.98px) {
        margin-right: 10px;

    }

    p {
        margin-bottom: 2px;

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #2F4655;

            @media (max-width: 1199.98px) {
                font-size: 14px;
            }
        }
    }

}

.view-summary {
    text-decoration: none;
    margin-right: 25px;
    text-align: center;
    cursor: pointer;

    @media (max-width: 1199.98px) {
        margin-right: 10px;
    }

    span {
        display: block;
        font-weight:500;
        font-size: 12px;
        line-height: 16px;
        color: #4D6166;

    }
}

.comments-btn {
    background: $white-color;
    border: 1px solid $theme-color-blue;
    border-radius: 32px;
    padding: 15px 25px;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-decoration-line: underline;
    color: $theme-color-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    em {
        color: $theme-color-blue;
        font-size: 20px;
        margin-right: 10px;
    }

    &:hover,
    &:focus,
    &:active {
        background: $white-color;
        border: 1px solid $theme-color-blue;
        color: $theme-color-blue;

    }
}

.btn-check:checked+.comments-btn,
.comments-btn.active,
.comments-btn.show,
.comments-btn:first-child:active,
:not(.btn-check)+.comments-btn:active {
    background: $white-color;
    border: 1px solid $theme-color-blue;
    color: $theme-color-blue;
}

.summary-view-modal {
    .summary-view-table {
        @media (max-width: 767.98px) {
            margin-left: 0px;
        }
    }

    .modal-header {
        border: none;
    }

    .modal-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
        color: $theme-color-blue;
        padding-left: 300px;
        margin-bottom: 0;

        @media (max-width: 767.98px) {
            padding-left: 0px;
        }
    }

    .modal-body {
        .cst-list-group {
            height: calc(100vh - 100px);

            @media (max-width: 991.98px) {
                display: block;
            }

            @media (max-width: 767.98px) {
                display: none;
            }
        }



    }

}

.no-row-available {
    font-size: 12px;
    font-weight: 600;
    background-color: #FFFF;
    color: #3A4A55;
    padding: 10px;
    text-align: center;
}

.disablebtn {
    pointer-events: none;
}

.summary-view-table {
    margin-left: 300px;

    .pass {
        color: $pass-green !important;
    }

    .fail {
        color: $fail-red !important;
    }

    .green-tick-box {
        width: 24px;
        height: 24px;
        background: $pass-green;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        em {
            color: $white-color;
            font-size: 16px;
        }
    }

    .red-box {
        width: 24px;
        height: 24px;
        background: $fail-red;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        em {
            color: $white-color;
            font-size: 16px;
        }
    }
}

.mycomment-sidebar {
    .offcanvas-header {
        border-bottom: 1px solid #DADCE0;
    }

    h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        color: #202124;
        margin-bottom: 0;

        em {
            color: $theme-color-blue;
            font-size: 22px;
            padding-right: 15px;
        }
    }

    .comment-list {
        border-bottom: 1px solid #DADCE0;
        padding: 15px 0;
        position: relative;

        .comment-id {
            font-weight: 600;
            font-size: 10px;
            line-height: 10px;
            color: #1F454E;
            background: #E3E8EC;
            border-radius: 20px;
            padding: 3px 8px;
            position: absolute;
            top: 18px;
        }

        .c-question {
            padding-left: 70px;
        }

        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #3C4043;
            margin-bottom: 0;
        }

        span.timestamp {
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            color: #5F6368;
        }
    }
}

.who-review-complete {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #4C5F6B;

    .progress {
        width: 120px;
        height: 8px;

        .bg-success {
            background-color: $pass-green;
        }

        .bg-pending {
            background-color: $fail-red;

        }
    }
}

.edit_action.disabled {
    opacity: 0.2;
}

.vendor-comment-box {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 8px 12px;
    margin-bottom: 12px;

    .vendor-name {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #1F454E;
        margin-bottom: 5px;
    }

    .vendor-comment {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 0;
        word-break: break-all;
    }
}

.response-list {
    border: 1px solid #DADCE0;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 12px;

    .response-circle {
        background: $theme-color-blue;
        border: 1px solid $theme-color-blue;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #FFFFFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    .response-details {
        padding-left: 50px;

        .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;

            &.rejected {
                color: $fail-red;

            }

            &.approved {
                color: $pass-green;

            }
        }

        .comment {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #232F33;
            margin-bottom: 10px;
        }

        .name {
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            color: #313A41;
            margin-bottom: 0;

            span {
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #5F6368;
                display: block;
            }
        }
    }
}

.btn-attach {
    background: #DEECF3;
    border: 1px solid #DEECF3;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #6B7F89;

    &:active {
        color: #6B7F89;
    }

    &:hover {
        background-color: #DEECF3;
        color: #6B7F89;
    }
}

.btn-check:checked+.btn-attach,
.btn-attach.active,
.btn-attach.show,
.btn-attach:first-child:active,
:not(.btn-check)+.btn-attach:active {
    background-color: #DEECF3;
    color: #6B7F89;
    border-color: #DEECF3;
}

.attachment-dropdown {
    li {
        border-bottom: 1px solid #D9E5EC;

        &:last-child {
            border-bottom: none;
        }
    }

    .dropdown-item {
        font-weight:500;
        font-size: 14px;
        line-height: 30px;
        color: #3196DF;

        em {
            margin-right: 5px;
        }

        &:active {
            background-color: $white-color;
        }
    }

}



.attachment-modal,
.confirm-modal {
    .modal-dialog {
        width: 380px;
    }

    .modal-header {
        background: #DEECF3;
        // border-bottom: 1px solid #A6D3EA;
        border-radius: 5px 5px 0px 0px;
        justify-content: center;

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            color: #194C59;
            margin-bottom: 0;
        }
    }

    .modal-body {
        padding-bottom: 0px;
        font-size: 16px;
    }

    .modal-footer {
        border-top: none;

        .link_btn {
            padding: 10px;
            border-radius: 6px;
            height: 40px;
            color: #7f8595;

        }
    }
}


.confirm-modal {
    .modal-header {
        border-bottom: 1px solid #A6D3EA;
        border-radius: 5px 5px 0px 0px;
        justify-content: center;

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            color: #194C59;
            margin-bottom: 0;
        }
    }

    .modal-body {
        padding: 40px;
        padding-bottom: 0px;
        text-align: center;
        font-size: 16px;
        @media (max-width: 767.98px) {
            padding: 15px;
        }
    }

    .modal-footer {
        justify-content: center;

        .link_btn {
            border: 1px solid #7f8595;
            width: 130px;
            padding: 10px;
            border-radius: 6px;
            height: 40px;
            color: #7f8595;
            @media (max-width: 767.98px) {
                width: 70px;
            }
        }
        .cst-btn{
            @media (max-width: 767.98px) {
                width: 70px;
            }
        }
    }
}

.attachment-details {
    display: flex;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 575.98px) {
        display: block;
    }

    a.cst-badge {
        background: #DCEBF0;
        border-radius: 20px;
        padding: 5px 12px;
        padding-bottom: 0;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $theme-color-blue;
        text-decoration: none;
        margin-right: 20px;
        width: 210px;
        position: relative;
        // border: 1px solid #009ade6b;

        em.close {
            position: absolute;
            right: 10px;
            color: #62747A;
        }
    }

    p {
        font-weight:500;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 0;
        min-width: 110px;
        span {
            font-style: italic;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            color: #3196DF;
            display: block;
        }
    }
}

.select-file {
    .cst-radio {
        border-bottom: 1px solid rgba(179, 197, 215, 0.6);
        margin-right: 0;
        padding: 10px 0;
    }

    .form-check-label {
        font-weight: 500;
        font-size: 15px;
        line-height: 42px;
        color: #516C78;
    }
}

.offcanvas-footer {
    padding: 16px;

    .cst-border-top {
        border-top: 1px solid rgba(179, 197, 215, 0.6);
        display: block;
        width: 100%;
        text-align: right;
        padding-top: 16px;

    }
}

.evidence-tab {
    width: 100%;

    .accordion-body {
        padding: 30px;
    }

    h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-decoration: underline;
        color: $theme-color-blue;
        display: inline-flex;
        margin-bottom: 0;
    }

    span.text {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        text-decoration: none;
        padding-left: 10px;
    }

    .accordion-button::after {
        display: none;
    }

    .summary-view-table {
        margin-left: 0;
        margin-top: 22px;

        .table {
            // table-layout: fixed;
            width: 100%;

            @media (max-width: 991.98px) {
                width: 1100px;
            }
        }

        .cst_table tbody tr {
            background: $white-color;
        }

        .info-icon {
            font-size: 20px;
        }
    }
}

.cst-alert {
    color: $error-color;
}

.success-msg {
    margin: 140px 0;
    text-align: center;

    img {
        width: 60px;
        margin-bottom: 50px;
    }

    h1 {
        font-size: 30px;
        font-weight: 800;
        margin-bottom: 24px;
    }

    p {
        font-size: 18px;
        line-height: 24px;
        margin: 0 auto 40px;
    }

    .btn {
        padding: 15px 30px;
        width: auto;
        font-weight: 600;
    }
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.display-full-width {
    padding-left: 0px !important;
}

.transition-disable {
    transition: none !important;
}

.transition-enable {
    transition: all 0.3s !important;
}

.modal-backdrop {
    height: 100vh;
    width: 100vw;
    background-color: rgba(#000000, 0.3) !important;
}

.read-only-radio {
    pointer-events: none;
    opacity: 0.8;
    cursor: default;
}

.text-area-control {
    min-height: 64px;
    max-height: 148px;
    resize: vertical;
}

.img-static {
    user-select: none !important;
    -webkit-user-drag: none;
}

.grecaptcha-badge{
    visibility: hidden;
}

.curated-solutions {
    .title {
        width: 750px;
        margin: 0 auto 60px;
        text-align: center;
        @media (max-width: 767.98px) {
            width: auto;
        }
        &::after {
            margin: 0 auto;
        }
    }


    .btn {
        // background: linear-gradient(4.95deg, #80BC00 -0.15%, #8EE338 97.53%);
        box-shadow: 0px 0px 20px rgba(130, 213, 47, 0.18);
        border-radius: 6px;
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        padding: 15px 35px;

        &:focus,
        &:active {
            outline: none;
            border-color: none;
            color: #fff;
        }
    }
}

.solution-card-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 15px #C5E0EC;
    padding: 13px 13px 4px;
    position: relative;
    cursor: pointer;
    @media (max-width: 767.98px) {
       margin-bottom: 20px;
    }
    .solution-img {
        width: 100%;
        height: 173px;
        border-radius: 4px;
        object-fit: cover;
        margin-bottom: 15px;
    }

    h1 {
        font-size: 20px;
        color: #17344A;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card-details {
        text-align: left;
        margin-bottom: 12px;

        label {
            color: #7F939E;
            font-size: 16px;
            font-weight: 500;
            line-height: 14px;
        }

        p {
            color: #333335;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 0;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // width: 120px;

            &.language-ellipsis {
                width: 250px;
            }
        }

    }

    .browser-list {
        img {
            margin-right: 10px;
        }
    }

    .cst-border-bottom {
        border-bottom: 1px solid rgba(151, 193, 221, 0.3);
    }

    .dha-badge {
        background-color: #ccc;
        border-radius: 4px;
        padding: 4px 10px;
        position: absolute;
        left: 20px;
        top: 20px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        &.green{
            background-color: #269D38;
        }
        &.orange{
            background-color: #FF8F10;
        }
        &.blue{
            background-color: #0883C3;
        }
        &.red{
            background-color: #F86E43;
        }
        &.purple{
            background-color: #E142D1;
        }
    }
}

.cst-form{
    .ng-select{
        width: auto!important;
    }
}

.back-arrow{
    margin-bottom: 0px;
    margin-right: 10px;
    font-size: 20px;
    color: #101727;
    cursor: pointer;
}
